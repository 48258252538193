@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.loader{
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  /* height: 25vh; */
  margin: 0;
  background: #173b1f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vh;
}

@keyframes fadeIn{
  100%{
    opacity: 1;
  }
}

div.spinner,
div.spinner:before,
div.spinner:after{
  --width: 34px;
  --height: 34px;
  --pixel-s: calc(var(--width) * 0.34);
  --pixel-m: calc(var(--width) * 0.89);
  animation: anim 2s linear infinite;
  border-radius: 50%;
  width:var(--width);
  height:var(--height);
  box-shadow: var(--pixel-s) var(--pixel-s) 0 #51b566, var(--pixel-m) var(--pixel-s) 0 #0f7d15, var(--pixel-s) var(--pixel-m) 0 #28853b;
  opacity: .86;
}

div.spinner:before,
div.spinner:after{
  content: '';
  display: block;
  opacity: .5;
  position: absolute;
}

div.spinner:before{
  animation-timing-function: cubic-bezier(0.470, 0.000, 0.745, 0.715);
  animation-delay: 1s;
}

div.spinner:after{
 animation-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
 animation-delay: 1.5s;
}

@keyframes anim{
  0%{
    animation-timing-function: linear;
    transform: scale(1) rotateZ(0deg);
  }
  50%{
    transform: scale(.89) rotateZ(180deg);
    animation-timing-function: linear;
  }
  51%{
    animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  100%{
    transform: scale(1) rotateZ(360deg);
  }
}

.greenlinks a{
  color: rgb(0 159 6);
}

.swiper-slide > img{
  cursor: pointer;
}

.interiormodal {
  position: fixed;
  top:0;
  left:0;
  height:100%;
  width: 100%;
  z-index:101;
  background-color: #000000e3;
  visibility: hidden;
  opacity: 0;
	transition: opacity 1s, visibility 0s 1s;
  padding:25px;
}

.interiormodal img{
  max-width:100%;
  max-height:100%;
  vertical-align: middle;
  margin: 0 auto;
}

.interiormodal.active {
  	opacity: 1;
  	visibility: visible;
  	transition: opacity 1s;
}

.arealoader{
  position: relative;
  height: 201px;
  background: #173b1f;
}